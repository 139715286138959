import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Head from "next/head";
import GetHelpWith from "../src/homepage/GetHelpWith";
import WelldoingHub from "../src/homepage/WelldoingHub";
import SearchHero from "../src/homepage/SearchHero";
import { useEffect } from "react";
import { setLayout } from "../src/redux/actions/layout";
import { resetProfileList } from "../src/redux/actions/profileList";
import { resetPractitionerFilter } from "../src/redux/actions/practitionerFilters";
import PersonalMatchingPlus from "../src/homepage/PersonalMatchingPlus";
import ClientsBanner from "../src/homepage/ClientsBanner";
import {
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useUtilityData } from "../src/utilities/contexts";
import { getStoryblokApi } from "@storyblok/react";

const Home = (props) => {
  const storyblokApi = getStoryblokApi();

  const dispatch = useDispatch();
  const router = useRouter();
  const utilityData = useUtilityData();
  const { success } = router.query;

  const {
    isOpen: isSuccessOpen,
    onClose: onSuccessClose,
    onOpen: onSuccessOpen,
  } = useDisclosure();

  const {
    isOpen: isFailedOpen,
    onClose: isFailedClose,
    onOpen: onFailedClose,
  } = useDisclosure();

  useEffect(() => {
    dispatch(setLayout("main"));
  }, [dispatch]);

  useEffect(() => {
    if (!success) return;

    if (success === "true") {
      onSuccessOpen();
    } else {
      onFailedOpen();
    }
  }, [success, onSuccessOpen]);

  useEffect(() => {
    if (Object.keys(utilityData).length !== 0) {
      dispatch(resetPractitionerFilter(utilityData));
      dispatch(resetProfileList());
    }
  }, [utilityData, dispatch]);

  const { slides_pres, slides_ghw, slides_wdh } = props.data;

  return (
    <div>
      <Modal isOpen={isSuccessOpen} onClose={onSuccessClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thank you!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Thanks for registering with MatchPlus.</p>
            <br />

            <p>
              Our matching specialists will start reaching out to suitably
              qualified practitioners to find the right practitioner for you.
            </p>
            <br />
            <p>
              In the meantime, please feel free to browse through our self-help
              articles and we will contact you shortly with a professional
              match.
            </p>
            <br />
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                onSuccessClose();
              }}
            >
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isFailedOpen} onClose={onFailedClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment Failed</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Thanks for registering with Welldoing PMS.</p>
            <br />
            <b>
              It appears that there was a problem with your payment, but
              don&apos;t worry.
            </b>
            <br /> <br />
            Please check your email to verify your account set up, after which
            you can log in to welldoing, access your dashboard and re-try your
            payment details.
            <br />
            <br /> <br />
            If there continues to be a problem, then please email us at
            info@welldoing.org and we will respond as soon as possible.
            <br />
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                onFailedClose();
              }}
            >
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Head>
        <title>Welldoing.org - Find a UK therapist or counsellor</title>
        <meta
          name="description"
          content="Find professionally verified mental health therapists and wellbeing coaches in your area."
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {/* breakpoints are set at base and md */}
      <div id="main" width="100%" height="500px" position="relative">
        <SearchHero />
        <ClientsBanner slides={slides_pres} />
        <GetHelpWith slides={slides_ghw} />
        <PersonalMatchingPlus />
        <WelldoingHub />
      </div>
      <Head>
        <title>Welldoing.org - Find a UK therapist or counsellor</title>
        <meta
          name="description"
          content="Find professionally verified mental health therapists and wellbeing coaches in your area."
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
    </div>
  );
};

export default Home;

export async function getStaticProps(context) {
  const data = {
    slides_pres: [
      {
        image: "/images/homepage/The Times.png",
        src: "",
        title: "The Times",
      },
      {
        image: "/images/homepage/c4 1.png",
        src: "",
        title: "Channel 4",
      },
      {
        image: "/images/homepage/stylist 1.png",
        src: "",
        title: "The Stylist",
      },
      {
        image: "/images/homepage/Daily Mail.png",
        src: "",
        title: "Daily Mail",
      },
      {
        image: "/images/homepage/Telegraph.png",
        src: "",
        title: "Telegraph",
      },
      {
        image: "/images/homepage/Red.png",
        src: "",
        title: "Red",
      },
    ],
    slides_ghw: [
      {
        image: "/images/homepage/anxiety.jpg",
        title: "Anxiety",
        description: "Anxiety is an increasingly common problem",
        practiceArea: "1",
      },
      {
        image: "/images/homepage/depression.jpg",
        title: "Depression",
        description: "A wide range of troubling emotions and physical effects",
        practiceArea: "2",
      },
      {
        image: "/images/homepage/relationship-counselling.jpg",
        title: "Relationship Problems",
        description: "Communication, trust, sex and intimacy",
        practiceArea: "52",
      },
      {
        image: "/images/homepage/stress.jpg",
        title: "Stress",
        description:
          "Too much stress can affect your physical and mental health",
        practiceArea: "66",
      },
    ],
    slides_wdh: [
      {
        image: "/images/web/career.jpg",
        title: "How to Identify Your Values and What You Need from Your Career",
        description: "by Claire Brown",
      },
      {
        image: "/images/web/xmas_cheers.jpg",
        title: "How to Minimise Family Conflict at Christmas",
        description: "by Nelisha Wickremasighne",
      },
      {
        image: "/images/web/ukrainewar.jpg",
        title:
          "It's Normal to Feel Anxious About Ukraine: How to Protect Yourself from Overwhelm",
        description: "by Graham Johnston",
      },
      {
        image: "/images/web/covid.jpg",
        title: "The Mental Health Impact of No More covid Restrictions",
        description: "by Alice McGurran",
      },
      {
        image: "/images/web/willsmith.jpg",
        title: "What Does Will Smiths Slap at the Oscars Tell Us About Anger?",
        description: "by Lucy Holbrook",
      },
    ],
  };

  if (!data) {
    return {
      redirect: {
        destination: "/no-data",
      },
    };
  }

  return {
    props: {
      data,
    },
    revalidate: 60,
  };
}
