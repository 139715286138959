import {
  Box,
  Container,
  IconButton,
  Text,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { useState } from "react";
import { IconRightArrow, IconLeftArrow } from "../../styles/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./gethelpwith.module.scss";
import Image from "next/image";
import Link from "next/link";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      size="sm"
      variant="carousel"
      aria-label="Get more information"
      icon={<IconRightArrow />}
      onClick={onClick}
      zIndex="5"
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      size="sm"
      variant="carousel"
      aria-label="Get more information"
      icon={<IconLeftArrow />}
      onClick={onClick}
      z-index="100"
    />
  );
}

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const GetHelpWith = ({ slides }) => {
  const [slider, setSlider] = useState(null);

  return (
    <>
      <Text
        width="100%"
        textAlign="center"
        textStyle="h1"
        color="brand.400"
        marginTop="80px"
        marginBottom="40px"
      >
        Get help with
      </Text>
      <SimpleGrid
        px="20px"
        display={{ base: "grid", md: "none" }}
        columns={2}
        spacing={6}
      >
        {slides.map((slide, index) => (
          <Link
            key={index}
            prefetch={false}
            href={`/counselling?practiceArea=${slide.practiceArea}`}
          >
            <Box position="relative" align="center">
              <Image
                src={slide.image}
                alt={slide.title}
                fill
                className="object-cover"
                borderRadius="20px"
              />
              <Box
                position="absolute"
                bottom="0"
                top="0"
                left="0"
                right="0"
                bgGradient="linear(to-t, rgba(10,10,10,0.8), transparent)"
              ></Box>
              <Box
                position="absolute"
                bottom="0"
                key={index}
                align="center"
                width="100%"
              >
                <Box width="50%" paddingBottom="16px">
                  <Text textStyle="h5" color="white">
                    {slide.title}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Link>
        ))}
      </SimpleGrid>
      <Container
        mt="40px"
        display={{ base: "none", md: "flex" }}
        width="100%"
        alignItems="center"
        maxW="container.xl"
        overflow="hidden"
      >
        <Box flex="1" height="100%" overflow="hidden">
          <Slider {...sliderSettings} ref={(slider) => setSlider(slider)}>
            {slides.map((slide, index) => (
              <Link
                key={index}
                prefetch={false}
                href={`/counselling?practiceArea=${slide.practiceArea}`}
              >
                <Box height="100%" key={index}>
                  <Box
                    key={index}
                    borderRadius="10px"
                    shadow="sm"
                    bg="brand.100"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    m="0px 12px 0px 12px"
                    alignItems="center"
                  >
                    <Box>
                      <Image
                        src={slide.image}
                        width={274}
                        height={325}
                        alt={slide.title}
                      />
                    </Box>
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      padding="16px"
                      bg="brand.100"
                      borderRadius="10px"
                    >
                      <Box mb="8px">
                        <Text textAlign="left" textStyle="h5">
                          {slide.title}
                        </Text>
                        <Text textAlign="left" textStyle="body2">
                          {slide.description}
                        </Text>
                      </Box>
                      <IconButton
                        aria-label="Get more information"
                        icon={<IconRightArrow />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))}
          </Slider>
          <Box w="100%" align="center">
            <Link href="/counselling" passHref prefetch={false}>
              <Button marginTop="40px">Find a therapist</Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default GetHelpWith;
