import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";

const Testimonial = ({ testHeader, testName, testText, testTitle, image }) => {
  const TestimonialContent = (props) => {
    const { children } = props;

    return (
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"lg"}
        p={8}
        rounded={"xl"}
        align={"center"}
        pos={"relative"}
        _after={{
          content: `""`,
          w: 0,
          h: 0,
          borderLeft: "solid transparent",
          borderLeftWidth: 16,
          borderRight: "solid transparent",
          borderRightWidth: 16,
          borderTop: "solid",
          borderTopWidth: 16,
          borderTopColor: useColorModeValue("white", "gray.800"),
          pos: "absolute",
          bottom: "-16px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {children}
      </Stack>
    );
  };

  const TestimonialHeading = (props) => {
    const { children } = props;

    return (
      <Heading as={"h3"} fontSize={"xl"}>
        {children}
      </Heading>
    );
  };

  const TestimonialText = (props) => {
    const { children } = props;

    return (
      <Text
        textAlign={"center"}
        color={useColorModeValue("gray.600", "gray.400")}
        fontSize={"sm"}
      >
        {children}
      </Text>
    );
  };

  const TestimonialAvatar = ({ src, name, title }) => {
    return (
      <Flex align={"center"} mt={8} direction={"column"}>
        <Avatar src={src} mb={2} />
        <Stack spacing={-1} align={"center"}>
          <Text fontWeight={600}>{name}</Text>
          <Text
            fontSize={"sm"}
            color={useColorModeValue("gray.600", "gray.400")}
          >
            {title}
          </Text>
        </Stack>
      </Flex>
    );
  };

  return (
    <Box width="350px" maxW="350px">
      <TestimonialContent>
        <TestimonialHeading>{testHeader}</TestimonialHeading>
        <TestimonialText>{testText}</TestimonialText>
      </TestimonialContent>
      <TestimonialAvatar src={image} name={testName} title={testTitle} />
    </Box>
  );
};

export default Testimonial;
