import { Box, Stack, Text } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/legacy/image";
import Testimonial from "./Testimonial";

const ClientsBanner = () => {
  return (
    <Box
      padding={[
        "40px 0px 80px 0px",
        "40px 0px 80px 0px",
        "100px 0px 200px 0px",
      ]}
      width="100%"
      align="center"
      position="relative"
      bg="#f5f7fb"
      clipPath={[
        "polygon(0% 0%, 100% 0%, 100% 100%,0% 100%)",
        "polygon(0% 0%, 100% 0%, 100% 100%,0% 100%)",
        "polygon(0% 0%, 50% 10%, 100% 0, 100% 90%, 50% 100%, 0 90%)",
      ]}
    >
      <Text textStyle="h1" color="brand.400" padding="80px 0px 80px 0px">
        Loved by therapists
      </Text>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: 10, md: 4, lg: 10 }}
        align="center"
        justifyContent="center"
        width="100%"
        //bg="blue"
        mx="auto"
      >
        <Testimonial
          testHeader="Ethos driven"
          testText="I'm really impressed; there seems to be a level of support, and investment of effort, in connecting therapists to clients that isn’t present in other directories."
          testName="SS, Psychotherapist"
          testTitle=""
          image={"/images/girlinred.jpg"}
        />
        <Testimonial
          testHeader="Great CPD and community"
          testText="Welldoing is great and has so many resources for clients and counsellors alike. The weekly CPD sessions are also really interesting and I’ll be signing up for more."
          testName="MR, Counsellor"
          testTitle=""
          image={"/images/man_staring.png"}
        />
        <Testimonial
          testHeader="Excellent support"
          testText="The professionalism and kindness from the Welldoing team has really impressed me. Welldoing supports their therapists which in turn helps us support our clients."
          testName="DU, Psychotherapist"
          testTitle=""
          image={
            "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80"
          }
        />
      </Stack>
      <Text
        textStyle="h1"
        color="brand.400"
        paddingTop="100px"
        maxW="container.sm"
        padding="80px 0px 80px 0px"
      >
        Recognised as leaders
      </Text>
      <Box
        width="100%"
        maxW="container.md"
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box
          margin="8px"
          height={{ base: "32px", xl: "40px" }}
          width={{ base: "260px", xl: "327px" }}
          position="relative"
        >
          <Image
            src="/images/homepage/Times.png"
            layout="fill"
            object-fit="contain"
            alt="welldoing client list"
          />
        </Box>
        <Box
          margin="8px"
          height={{ base: "32px", xl: "40px" }}
          width={{ base: "31px", xl: "39px" }}
          position="relative"
        >
          <Image
            src="/images/homepage/Red.png"
            object-fit="contain"
            layout="fill"
            alt="welldoing client list"
          />
        </Box>
        <Box
          margin="8px"
          height={{ base: "32px", xl: "40px" }}
          width={{ base: "192px", xl: "241px" }}
          position="relative"
        >
          <Image
            layout="fill"
            src="/images/homepage/Telegraph.png"
            object-fit="contain"
            alt="welldoing client list"
          />
        </Box>
        <Box
          margin="8px"
          height={{ base: "32px", xl: "40px" }}
          width={{ base: "153px", xl: "192px" }}
          position="relative"
        >
          <Image
            layout="fill"
            src="/images/homepage/Stylist.png"
            object-fit="contain"
            alt="welldoing client list"
          />
        </Box>
        <Box
          margin="8px"
          height={{ base: "32px", xl: "40px" }}
          width={{ base: "24px", xl: "30px" }}
          position="relative"
        >
          <Image
            src="/images/homepage/C4.png"
            layout="fill"
            objectFit="contain"
            alt="welldoing client list"
          />
        </Box>
        <Box
          margin="8px"
          height={{ base: "32px", xl: "40px" }}
          width={{ base: "188px", xl: "236px" }}
          position="relative"
        >
          <Image
            src="/images/homepage/DailyMail.png"
            layout="fill"
            object-fit="contain"
            alt="welldoing client list"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ClientsBanner;
