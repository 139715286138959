import {
  Box,
  Container,
  IconButton,
  Text,
  chakra,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Stack,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { IconRightArrow, IconLeftArrow, IconSearch } from "../../styles/icons";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArticleSearchInput from "../articles/ArticleSearchInput";
import Link from "next/link";
import { getStoryblokApi } from "@storyblok/react";
import { useRouter } from "next/router";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      size="sm"
      variant="carousel"
      aria-label="Next"
      icon={<IconRightArrow />}
      onClick={onClick}
      zIndex="5"
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      size="sm"
      variant="carousel"
      aria-label="Previous"
      icon={<IconLeftArrow />}
      onClick={onClick}
      z-index="100"
    />
  );
}

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const WelldoingHub = ({ slides }) => {
  const storyblokApi = getStoryblokApi();
  const [articles, setArticles] = useState([]);
  const router = useRouter();

  useEffect(() => {
    const getLatest = async () => {
      const {
        data: { stories },
      } = await storyblokApi.get(`cdn/stories`, {
        starts_with: "article",
        per_page: 8,
        resolve_relations: ["article.authors"],
        sort_by: "content.legacy_published_at:desc",
      });
      setArticles(stories);
    };
    getLatest();
  }, []);

  const [slider, setSlider] = useState(null);

  return (
    <Box py="80px" width="100%">
      <Container maxW="container.xl" width="100%" alignItems="center">
        <Box
          width="100%"
          px="70px"
          display="flex"
          justifyContent="space-between"
        >
          <Text
            width="100%"
            textAlign={{ base: "center", md: "left" }}
            textStyle="h1"
            color="brand.400"
          >
            Recent articles
          </Text>
          <Stack
            display={{ base: "none", md: "flex" }}
            justifyContent="center"
            alignItems="center"
            w="50%"
          >
            <ArticleSearchInput />
          </Stack>
        </Box>
      </Container>
      <Container
        mt="40px"
        display="flex"
        height="18rem"
        width="100%"
        alignItems="center"
        maxW="container.xl"
        overflow="hidden"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          transform="translate(10px)"
        >
          <SamplePrevArrow
            onClick={() => slider?.slickPrev()}
            style="z-index:100"
          />
        </Box>
        <Box flex="1" height="100%" overflow="hidden">
          <Slider {...sliderSettings} ref={(slider) => setSlider(slider)}>
            {articles.map((slide, index) => (
              <Box
                height="100%"
                key={index}
                bg="white"
                cursor="pointer"
                onClick={() => router.push(`/article/${slide.slug}`)}
              >
                <Box
                  key={index}
                  height="18rem"
                  borderRadius="10px"
                  overflow="hidden"
                  shadow="sm"
                  p="50px 20px 17px 20px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  m="0px 12px 0px 12px"
                  align="center"
                  position="relative"
                >
                  <Image
                    src={slide.content?.headerImage?.filename}
                    alt={slide.content.title}
                    className="object-cover"
                    zIndex={0}
                    fill
                    sizes="400px"
                  />
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    left="0"
                    bottom="0"
                    bgGradient="linear(transparent 40%, rgba(0,0,0,0.7), black)"
                    borderRadius="10px"
                  />
                  <chakra.div z-index="10">
                    <chakra.div
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      padding="4px"
                      position="absolute"
                      bottom="0"
                    >
                      <chakra.div>
                        <Text textAlign="left" textStyle="h5" color="white">
                          {slide.content.title}
                        </Text>
                        <Text textStyle="body2" textAlign="left" color="white">
                          {slide.content.authors[0]?.name}
                        </Text>
                      </chakra.div>
                      <IconButton
                        aria-label="View article"
                        icon={<IconRightArrow />}
                        onClick={() => router.push(`/article/${slide.slug}`)}
                      />
                    </chakra.div>
                  </chakra.div>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          transform="translate(-10px)"
        >
          <SampleNextArrow onClick={() => slider?.slickNext()} />
        </Box>
      </Container>
      <Container maxW="container.xl" width="100%" alignItems="center">
        <Box width="100%" px="70px" align="center" p="40px">
          <Link href="/article" prefetch={false}>
            <Button>View all articles</Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default WelldoingHub;
