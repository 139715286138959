import { Box, Button, Text, Icon, VStack } from "@chakra-ui/react";
import Link from "next/link";
import Image from "next/image";
import { ImCheckmark } from "react-icons/im";

const stackStyle = {
  width: { base: "100%", md: "45%" },
};

const PersonalMatchingPlus = () => {
  return (
    <Box
      my="40px"
      padding={[
        "40px 0px 40px 0px",
        "40px 0px 40px 0px",
        "100px 0px 200px 0px",
      ]}
      width="100%"
      align="center"
      position="relative"
      bg="#f5f7fb"
      clipPath={[
        "polygon(0% 0%, 100% 0%, 100% 100%,0% 100%)",
        "polygon(0% 0%, 100% 0%, 100% 100%,0% 100%)",
        "polygon(0% 0%, 50% 10%, 100% 0, 100% 90%, 50% 100%, 0 90%)",
      ]}
    >
      <Box
        width="100%"
        marginTop={{base: "0px", md: "80px"}}
        marginBottom={{base: "0px", md: "60px"}}
        display="flex"
        flexDirection="row"
        justifyContent={{base: "center", md: "flex-start"}}
        borderRadius={{base: "0px", md: "20px"}}
        mx={{base: "0px", md: "24px"}}
        maxW="container.lg"
      >
        <Box
          sx={stackStyle}
          p="50px"
          my="auto"
          display="flex"
          flexDirection="column"
          alignItems={{base: "center", md: "flex-start"}}
          flex="6"
          width="100%"
        >
          <Text
            width={{base: "75%", md: "100%"}}
            textAlign={{base: "center", md: "left"}}
            textStyle={{base: "h2", md: "h1"}}
            color={{base: "brand.400", md: "brand.400"}}
          >
            Welldoing MatchPlus
          </Text>
          <Text textStyle="body1" py="10px" align="left">
            Book counselling with a qualified therapist quickly and easily. Talk
            online, in person, or by phone.
          </Text>
          <div className='h-[300px] w-full relative block md:hidden'>
            <Image
              display={{base: "block", md: "none"}}
              src="/images/homepage/mental-health-counsellor.jpg"
              alt="mental health practitioners"
              fill
              className='object-cover'
              sizes='400px'
            />
          </div>
          <VStack spacing={3} alignItems="flex-start" py="16px">
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Icon
                as={ImCheckmark}
                color="brand.400"
                paddingRight="8px"
                boxSize={30}
              />
              <Text textStyle="h5">Get matched within 3 working days</Text>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Icon
                as={ImCheckmark}
                color="brand.400"
                paddingRight="8px"
                boxSize={30}
              />
              <Text textStyle="h5"> Dedicated matching specialists</Text>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Icon
                as={ImCheckmark}
                color="brand.400"
                paddingRight="8px"
                boxSize={30}
              />
              <Text textStyle="h5">Precision matching questionnaire</Text>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Icon
                as={ImCheckmark}
                color="brand.400"
                paddingRight="8px"
                boxSize={30}
              />
              <Text textStyle="h5">Speak to a human, not a bot</Text>
            </Box>
          </VStack>

          <Box w="100%" align="center">
            <Link href="/personalised-matching-service" passHref prefetch={false}>
              <Button marginTop="40px">Find out more</Button>
            </Link>
          </Box>
        </Box>
        <div className='w-[400px] relative hidden md:block rounded-lg overflow-hidden'>
          <Image
            display={{base: "block", md: "none"}}
            src="/images/homepage/mental-health-counsellor.jpg"
            alt="mental health practitioners"
            fill
            className='object-cover'
            sizes='400px'
          />
        </div>
      </Box>
    </Box>
  );
};

export default PersonalMatchingPlus;
