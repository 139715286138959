import * as C from '../constants';
import { DEFAULT_FILTER_STATE } from '../reducers/practitionerFilters';

const practitionerFiltersUpdate = (searchDetails) => ({
  type: C.UPDATE_SEARCH_PRACTITIONER,
  payload: searchDetails,
});

export const createLoadedFilter = (partialFilter, utilityData) => {
  return {
    ...partialFilter,
    practitionerType:
      partialFilter.practitionerType.length > 0
        ? partialFilter.practitionerType
        : utilityData.practitionerType,
    concessions:
      partialFilter.concessions.length > 0
        ? partialFilter.concessions
        : utilityData.concessions,
    sessionTypes:
      partialFilter.sessionTypes.length > 0
        ? partialFilter.sessionTypes
        : utilityData.sessionTypes,
    clientGroups:
      partialFilter.clientGroups.length > 0
        ? partialFilter.clientGroups
        : utilityData.clientGroups,
    genders:
      partialFilter.genders.length > 0
        ? partialFilter.genders
        : utilityData.genders,
    ageGroups:
      partialFilter.ageGroups.length > 0
        ? partialFilter.ageGroups
        : utilityData.ageGroups,
    ethnicity:
      partialFilter.ethnicity.length > 0
        ? partialFilter.ethnicity
        : utilityData.ethnicities,
    therapistPracticeAreas:
      partialFilter.therapistPracticeAreas.length > 0
        ? partialFilter.therapistPracticeAreas
        : utilityData.therapistPracticeAreas,
    therapistPractitionerModalities:
      partialFilter.therapistPractitionerModalities.length > 0
        ? partialFilter.therapistPractitionerModalities
        : utilityData.therapistPractitionerModalities,
    languages:
      partialFilter.languages.length > 0
        ? partialFilter.languages
        : utilityData.languages,
    religion:
      partialFilter.religion.length > 0
        ? partialFilter.religion
        : utilityData.religions,
  };
};

export const resetPractitionerFilter = (utilityData) => (dispatch) => {
  let newInitialisedFilter = createLoadedFilter(
    DEFAULT_FILTER_STATE,
    utilityData
  );
  dispatch(practitionerFiltersUpdate(newInitialisedFilter));
};
